import axios from 'axios'
import {Dispatch} from 'redux'
import {
    GUESTBOOK_ADD_LOADED_POSTS, GUESTBOOK_SET_CAPTCHA,
    GUESTBOOK_SET_LOADED_PAGE, GUESTBOOK_SET_POST_RESULT, GUESTBOOK_SET_POSTED,
    GUESTBOOK_SET_POSTS,
    GUESTBOOK_SET_TOTAL_PAGES, USER_SET_SUBSCRIBE_RESULT,
} from '../types'

export const getPosts = () => {
    return async (dispatch: Dispatch) => {
        try {
            const posts = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/guestbook/messages`)
                .then(res => res.data)
            dispatch({type: GUESTBOOK_SET_POSTS, payload: posts.content})
            dispatch({type: GUESTBOOK_SET_LOADED_PAGE, payload: 0})
            dispatch({type: GUESTBOOK_SET_TOTAL_PAGES, payload: posts.totalPages})
        } catch (err) {
            console.log(err)
        }
    }
}

export const loadPosts = (page) => {
    return async (dispatch: Dispatch) => {
        try {
            const posts = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/guestbook/messages?page=${page}`)
                .then(res => res.data)
            dispatch({type: GUESTBOOK_ADD_LOADED_POSTS, payload: posts.content})
            dispatch({type: GUESTBOOK_SET_LOADED_PAGE, payload: page})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getCaptcha = () => {
    return async (dispatch: Dispatch) => {
        try {
            const captchaImg = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/guestbook/captcha`)
                .then(res => res.data)
            dispatch({type: GUESTBOOK_SET_CAPTCHA, payload: captchaImg})
        } catch (err) {
            console.log(err)
        }
    }
}

export const createPost = (values) => {
    return async (dispatch: Dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/site/guestbook?captcha=${values.captcha}`, {
                name: values.name,
                email: values.email,
                url: values.url,
                message: values.message,
                city: values.city
            })
                .then(res => res.data)
            dispatch({type: GUESTBOOK_SET_POSTED, payload: true})
            dispatch({type: GUESTBOOK_SET_POST_RESULT, payload: []})
            getCaptcha()
        } catch (err: any) {
            dispatch({type: GUESTBOOK_SET_POSTED, payload: false})
            if (err.response.data.messages) {
                dispatch({type: GUESTBOOK_SET_POST_RESULT, payload: err.response.data.messages})
            }
            console.log(err)
        }
    }
}
