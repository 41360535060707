import {createTheme} from '@material-ui/core/styles'
import {red} from '@material-ui/core/colors'

const theme = createTheme({
    palette: {
        primary: {
            main: '#0029FF',
            dark: '#000'
        },
        secondary: {
            main: '#000000',
        },
        error: {
            main: red.A400,
        },
        background: {
            //default: '#F6F6F6',
            default: '#f0f5fb',
        },
        success: {
            main: '#00a464',
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h1: {
            fontSize: 48,
            marginBottom: 20,
            fontFamily: 'Zantroke',
            color: '#DBDBDB'
        },
        h6: {
            marginBottom: 10,
            fontWeight: 400,
        },
        caption: {
            fontWeight: 300,
            fontSize: 12,
        },
    },
    overrides: {
        MuiMenuItem: {
            root: {
                fontSize: '0.875rem',
            },
        },
        MuiPaper: {
            elevation10: {
                //boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
                boxShadow: 'none',
                borderRadius: 10,
            },
        },
        MuiInput: {
            root: {
                fontSize: '0.9rem',
                paddingLeft: 5,
            },
            underline: {
                '&:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
                },
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: '0.9rem',
            },
            marginDense: {
                marginLeft: 5,
            },
            shrink: {
                top: 4,
                transform: 'translate(0, 1.5px) scale(0.8)',
            },
            outlined: {
                '&.MuiInputLabel-shrink': {
                    transform: 'translate(11px, -9px) scale(0.75)',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                '&:not(.Mui-focused):hover $notchedOutline': {
                    border: '2px solid rgba(0,0,0,0.2)',
                },
            },
            inputMarginDense: {
                paddingTop: 12,
                paddingBottom: 10,
                fontSize: 14,
            },
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiButton: {
            root: {},
            contained: {
                fontWeight: 600,
                fontSize: 18,
                boxShadow: 'none',
            },
            outlinedSizeLarge: {
                border: '2px solid #0029FF',
                borderRadius: 10,
                padding: '5px 15px',
                '&:hover': {
                    borderWidth: 2
                },
                '& .MuiButton-label': {
                    textTransform: 'uppercase',
                }
            },
            label: {
                textTransform: 'none',
                fontWeight: 600,
                margin: '0 20px'
            },
            containedSecondary: {
                color: '#fff',
                border: '2px solid transparent',
                '&:hover':{
                    backgroundColor: 'transparent',
                    border: '2px solid #000',
                    color: '#000'
                }
            }
        },
    },
})

export default theme
