import axios from 'axios'
import {Dispatch} from 'redux'
import {
    GALLERY_ADD_LOADED_PHOTOS,
    GALLERY_SET_CURRENT_GALLERY,
    GALLERY_SET_CURRENT_GALLERY_PHOTOS,
    GALLERY_SET_LIST,
    GALLERY_SET_LOADED_PAGE,
    GALLERY_SET_TOTAL_PAGES, GALLERY_SET_YEARS
} from '../types'

export const getGalleryYears = () => {
    return async (dispatch: Dispatch) => {
        try {
            const years: number[] = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/gallery/years`)
                .then(res => res.data)
            dispatch({type: GALLERY_SET_YEARS, payload: years.reverse()})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getGalleryItems = (year: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const items = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/gallery/years/${year}?size=100`)
                .then(res => res.data)
            dispatch({type: GALLERY_SET_LIST, payload: items.content})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getGalleryLastItems = () => {
    return async (dispatch: Dispatch) => {
        try {
            const items = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/gallery/last`)
                .then(res => res.data)
            dispatch({type: GALLERY_SET_LIST, payload: items.content})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getCurrentGallery = (id) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({type: GALLERY_SET_CURRENT_GALLERY, payload: null})
            const event = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/gallery/${id}`)
                .then(res => res.data)
            dispatch({type: GALLERY_SET_CURRENT_GALLERY, payload: event})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getGalleryPhotos = (id) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({type: GALLERY_SET_CURRENT_GALLERY_PHOTOS, payload: null})
            const photos = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/gallery/${id}/photos?size=12`)
                .then(res => res.data)
            dispatch({type: GALLERY_SET_CURRENT_GALLERY_PHOTOS, payload: photos.content})
            dispatch({type: GALLERY_SET_LOADED_PAGE, payload: 0})
            dispatch({type: GALLERY_SET_TOTAL_PAGES, payload: photos.totalPages})
        } catch (err) {
            console.log(err)
        }
    }
}

export const loadPhotos = (id, page) => {
    return async (dispatch: Dispatch) => {
        try {
            const photos = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/gallery/${id}/photos?page=${page}&size=12`)
                .then(res => res.data)
            dispatch({type: GALLERY_ADD_LOADED_PHOTOS, payload: photos.content})
            dispatch({type: GALLERY_SET_LOADED_PAGE, payload: page})
        } catch (err) {
            console.log(err)
        }
    }
}