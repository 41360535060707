import {Button, createStyles, makeStyles} from '@material-ui/core'
import React, {useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {GALLERY_SET_CURRENT_YEAR} from '../../redux/types'
import {IState} from '../../utils/models/IState'
import OctagonButton from './OctagonButton'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            margin: 0,
            '& .MuiButton-text': {
                margin: 0
            },
            '& button span.MuiButton-label': {
                margin: 0,
                padding: 0
            }
        }
    }),
)

interface IYearsPanel {
    title?: string
    data: number[]
    withRedirect?: boolean
}

export const YearsPanel = ({title, data = [], withRedirect = false}: IYearsPanel) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const {currentYear} = useSelector((state: IState) => state.gallery, shallowEqual)

    const handleClick = (year) => () => {
        dispatch({type: GALLERY_SET_CURRENT_YEAR, payload: year})
        withRedirect && history.push('/gallery')
    }

    return (
        <div className={classes.root}>
            {title && <b style={{fontSize: 16}}>{title}</b>}
            {data.map((year) => {
                const shortYear = year.toString().split('').slice(2).join('')
                return (
                    year === currentYear ?
                        <OctagonButton key={year} margin={0} variant={'contained'}>{`'${shortYear}`}</OctagonButton>
                        :
                        <Button style={{height: 49}} onClick={handleClick(year)} key={year} variant={'text'}>{`'${shortYear}`}</Button>
                )
            })}
        </div>
    )
}

export default YearsPanel