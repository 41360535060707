import {createStyles, IconButton, makeStyles, Theme, withWidth, WithWidthProps} from '@material-ui/core'
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints'
import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {getItems} from '../../../redux/actions/food'
import {IFoodCategory} from '../../../utils/models/IFoodState'
import {IState} from '../../../utils/models/IState'
import OctagonButton from '../OctagonButton'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            cursor: 'pointer',
            borderRadius: 4,
            minHeight: 64,
            width: 'auto',
            '&:hover': {
                // background: 'rgba(0, 0, 0, 0.02)'
            }
        },
        icon: {
            width: 64,
            height: 64,
            marginRight: 10
        },
        text: {
            fontFamily: 'Zantroke',
            fontWeight: 500,
            fontSize: 18,
            width: 215
        }
    }),
)

interface IFoodCategoryProps extends IFoodCategory {
    width?: Breakpoint
}

export const FoodCategory: React.FC<IFoodCategoryProps> = ({id, title, imagePath, width}) => {
    const classes = useStyles()
    const {currentCategoryId} = useSelector((state: IState) => state.food, shallowEqual)
    const dispatch = useDispatch()
    const handleClick = (id: string) => () => dispatch(getItems(id))
    const imgSrc = `${process.env.REACT_APP_API_URL}/files/${imagePath}`
    return (
        <div onClick={handleClick(id)} key={id} className={classes.root}>
            {currentCategoryId === id ?
                <OctagonButton className={classes.icon} disabled icon={imgSrc} iconSize={30} margin={10}/> :
                <IconButton disabled className={classes.icon}>
                    <img src={imgSrc} style={{width: 30, height: 30}}/>
                </IconButton>
            }
            {width !== 'xs' &&
            <div className={classes.text} style={{fontSize: width === 'sm' ? 15 : 18}}>{title}</div>
            }
        </div>
    )
}

export default withWidth()(FoodCategory)