import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Box, Button, createStyles, makeStyles, Menu, MenuItem, Theme, withWidth} from '@material-ui/core'
import {useHistory} from 'react-router-dom'
import {getMainMenu} from '../../redux/actions/app'
import {IMainMenu, IMainMenuItem} from '../../utils/models/IMainMenu'
import {IState} from '../../utils/models/IState'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 15,
            position: 'relative',
            top: 6
        },
        button: {
            color: '#fff',
            fontSize: 19,
            fontWeight: 400,
            borderBottom: '7px solid transparent',
            borderRadius: 0,
            padding: '0 0 14px 0',
            //marginRight: 35,
            '& .MuiButton-label': {
                margin: 0
            },
            '&:last-child': {
                marginRight: 0
            },
            '&:hover, &.active': {
                borderBottomColor: theme.palette.primary.main
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 16,
            }
        }
    }),
)

export const TopNav = ({width}) => {

    const history = useHistory()
    const classes = useStyles()
    const {menuItems} = useSelector((state: IState) => state.app, shallowEqual)
    const [panelItemsCount] = useState(5)
    const [navData, setNavData] = useState<IMainMenu>({items:[]})
    const [morePanelItems, setMorePanelsItem] = useState<IMainMenu>({items:[]})
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClick = (item: IMainMenuItem) => () => {
        handleClose()
        console.log()
        history.push(`/${item.code}`)
    }

    useEffect(()=>{
        !menuItems.items.length &&
        dispatch(getMainMenu())
    }, [])

    useEffect(()=>{
        switch (width) {
            case 'sm':
                const newData = menuItems.items.slice(0, panelItemsCount)
                setNavData({items: newData})
                setMorePanelsItem({items:menuItems.items.slice(-(menuItems.items.length - panelItemsCount))})
                break
            default:
                setNavData(menuItems)
        }
    }, [width, menuItems])

    return (
        <Box className={classes.root}>
            {width !== 'xs' &&
            navData.items.map((item: IMainMenuItem, index) => (
                <Button
                    className={classes.button + ' ' + ((('/' + item.code) === history.location.pathname) ? 'active' : '')}
                    variant={'text'}
                    key={'lnk' + index}
                    onClick={handleClick(item)}
                    disableRipple
                >
                    {item.title}
                </Button>
            ))}
            {
                width === 'sm' &&
                <Box>
                    <Button disableRipple aria-controls={'more'} aria-haspopup='true' onClick={handleMoreClick}
                            variant={'text'} className={classes.button}>Еще</Button>
                    <Menu
                        id={'more'}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        getContentAnchorEl={null}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        transformOrigin={{vertical: 'top', horizontal: 'left'}}
                        onClose={handleClose}
                        elevation={1}
                    >
                        {morePanelItems.items.map((item, key) => (
                            <MenuItem key={key} onClick={handleClick(item)}>
                                {item.title}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            }
        </Box>
    )
}
export default withWidth()(TopNav)