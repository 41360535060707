import SocialPanel from 'components/misc/SocialPanel'
import React from 'react'
import {Box, Container, createStyles, Grid, Hidden, Icon, IconButton, makeStyles, Theme} from '@material-ui/core'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {showMenu} from '../../redux/actions/app'
import {IState} from '../../utils/models/IState'
import Logo from '../misc/Logo'
import TopNav from './TopNav'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: '#000',
            color: '#fff',
            position: 'sticky',
            top: -120,
            zIndex: 1100,
            [theme.breakpoints.only('xs')]: {
                top: 0
            }
        },
        container: {
            maxWidth: 1440,
            margin: 'auto',
            padding: '45px 20px 0 20px',
            alignItems: 'center',
            [theme.breakpoints.only('xs')]: {
                padding: '15px 20px 0 20px',
            }
        },
        logo: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.only('xs')]: {
                textAlign: 'center',
                alignItems: 'center',
                flexDirection: 'inherit',
                '& img': {
                    height: 30
                }
            }
        },
        contact: {
            fontWeight: 700,
            padding: '4px 0 0 45px',
            '& span': {
                fontWeight: 'normal'
            },
            '& a': {
                color: '#fff'
            },
            '&.email': {
                padding: '0',
                // background: 'url(/assets/images/icons/email.svg) no-repeat left 2px',
            },
            '&.phone': {
                background: 'url(/assets/images/icons/phone.svg) no-repeat left 5px',
            },
            '&.location': {
                background: 'url(/assets/images/icons/location.svg) no-repeat left 5px',
            },
            [theme.breakpoints.down('sm')]: {
                lineHeight: 1.2,
                backgroundSize: '16px !important',
                backgroundPosition: '16px center !important',
                '& br': {
                    display: 'none'
                }
            }
        },
        menuButton: {
            padding: 0,
            '& .material-icons': {
                color: '#fff',
                fontSize: '36px!important'
            },

        }
    }),
)

export const Header = () => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const {showMenu: menuIsShown} = useSelector((state: IState) => state.app, shallowEqual)

    const handleMenu = () => {
        dispatch(showMenu(!menuIsShown))
    }

    const handleLogoClick = () => {
        history.push('/')
    }

    return (
        <Box className={classes.root}>
            <Container disableGutters className={classes.container}>
                <Grid container spacing={0} style={{alignItems: 'center'}}>
                    <Hidden smUp>
                        <Grid item xs={2}>
                            <IconButton disableRipple className={classes.menuButton} onClick={handleMenu}>
                                <Icon>{menuIsShown ? 'clear' : 'menu'}</Icon>
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Grid item xs={6} sm={5} md={3} className={classes.logo}>
                        <Logo size={300} onClick={handleLogoClick} />
                    </Grid>
                    <Grid item xs={4} sm={2} md={1}>
                        <Hidden smUp>
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center'
                            }}>
                                <IconButton
                                    href={'tel:+78182471479'}
                                    disableRipple
                                    className={classes.menuButton}>
                                        <Icon>settings_phone</Icon>
                                </IconButton>
                                <IconButton
                                    style={{marginLeft: 20}} href={'https://t.me/kolesoclub'}
                                    target={'_blank'}
                                    disableRipple
                                    className={classes.menuButton}>
                                        <img style={{height: 26}} src="/assets/images/icons/telegram.svg" />
                                </IconButton>
                            </Box>
                        </Hidden>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item sm={5} md={8} container>
                            <Hidden smDown>
                                <Grid item xs={12} md={4} className={classes.contact + ' location'}>
                                    Архангельск,<br/> ул. Гайдара, 4/1
                                </Grid>
                                <Grid item xs={12} md={4} className={classes.contact + ' phone'}>
                                    47-14-79 <span>(c 12:00)</span>, <br/>
                                    +7 902 286-14-79
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} md={4} className={classes.contact + ' email'}>
                                {/*<a href={'mailto:mail@arkoleso.ru'}>mail@arkoleso.ru</a>*/}
                                <SocialPanel variant={'invertedSM'} />
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
                <TopNav/>
            </Container>
        </Box>
    )
}
export default Header