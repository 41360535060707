import {Button, createStyles, Grid, Icon, makeStyles, Theme} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import React, {useEffect} from 'react'
import ym from 'react-yandex-metrika'
import Layout from '../components/layout/Layout'
import {Helmet} from 'react-helmet-async'
import ContainerTitled from '../components/misc/ContainerTitled'
import Map from '../components/misc/Map'
import OctagonButton from '../components/misc/OctagonButton'
import {useHistory, useLocation} from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontWeight: 500,
            '& h1': {
                fontFamily: 'Zantroke',
                fontWeight: 500
            },
            '& h3': {
                fontWeight: 700,
                fontSize: 24
            },
            '& ul': {
                margin: '0 30px 30px 0',
                padding: 0,
                listStyle: 'none',
                background: 'url(/assets/images/misc/border-h.svg) repeat-x left bottom',
                backgroundSize: '16px',
                paddingBottom: 30,
                [theme.breakpoints.only('xs')]: {
                    marginRight: 0
                },
                [theme.breakpoints.up('sm')]: {
                    '&:last-child': {
                        background: 'none'
                    },
                },
                '& li': {
                    padding: '5px 20px 5px 0',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    '& p': {
                        padding: 0,
                        margin: 0,
                        fontSize: 'inherit'
                    },
                    '& .material-icons': {
                        marginRight: 10,
                        color: '#DBDBDB'
                    }
                }
            }
        },
        social: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: 30,
            [theme.breakpoints.only('xs')]: {
                marginBottom: 15
            },
            '& p': {
                fontSize: '1.2vw',
                fontWeight: 500,
                [theme.breakpoints.only('xs')]: {
                    fontSize: '4vw',
                },
                [theme.breakpoints.only('sm')]: {
                    fontSize: '2vw',
                },
                [theme.breakpoints.only('md')]: {
                    fontSize: '1.2vw',
                },
                [theme.breakpoints.only('lg')]: {
                    fontSize: '1vw',
                },
                [theme.breakpoints.only('xl')]: {
                    fontSize: 18,
                },
            }
        }
    }),
)

export const ContactsPage = () => {
    const {pathname} = useLocation()
    const classes = useStyles()
    const history = useHistory()

    useEffect(() => {
        ym('hit', pathname)
    }, [])

    const handleGotoEvents = () => {
        history.push('/events')
    }

    return (
        <Layout title={'Контактная информация'} variant={'center'}>
            <Helmet>
                <title>Колесо: Контактная информация</title>
            </Helmet>
            <ContainerTitled>
                <Grid container className={classes.root}>
                    <Grid item xs={12} sm={7}>
                        <h1 style={{marginBottom: 60}}>Архангельск, ул. Гайдара 4/1</h1>
                        <h3>Как до нас добраться:</h3>
                        <ul>
                            <li><Icon>directions_bus</Icon>остановка ул. ГАЙДАРА</li>
                            <li><Icon>train</Icon>от Ж/Д ВОКЗАЛА - автобусы № 6</li>
                            <li><Icon>directions_boat</Icon>от МОР. РЕЧ. ВОКЗАЛА - 42, 1, 104</li>
                            <li><Icon>flight</Icon>из АЭРОПОРТА - 12</li>
                        </ul>
                        <ul>
                            <li><Icon>phone</Icon>47-14-79 (+7 902-286-14-79)</li>
                        </ul>
                        <ul>
                            <li><Icon>mail</Icon><p>Офис клуба: <a
                                href={'mailto:mail@arkoleso.ru'}>mail@arkoleso.ru</a></p></li>
                            {/*<li><Icon>mail</Icon><p>Поддержка сайта: <a
                                href={'mailto:support@arkoleso.ru'}>support@arkoleso.ru</a></p></li>*/}
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <img src={'/assets/images/clubView.svg'} width={'90%'}/>
                        <h3>Организация концертов:</h3>
                        <ul>
                            <li><p>Александр Мезенцев: <a href={'mailto:almez@mail.ru'}>almez@mail.ru</a></p></li>
                            <li><p>Павел Мезенцев: <a href={'mailto:palsanych@rambler.ru'}>palsanych@rambler.ru</a></p>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Присоединяйтесь к нам в соцсетях:</h3>
                        <Grid container style={{marginBottom: 20}}>
                            <Grid item xs={12} sm={6} md={4} className={classes.social}>
                                <OctagonButton
                                    href={'https://vk.com/clubkoleso'}
                                    target={'_blank'}
                                    icon={'/assets/images/icons/vk.svg'}
                                />
                                <p>vk.com/<b>clubkoleso</b></p>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} className={classes.social}>
                                <OctagonButton
                                    href={'https://www.facebook.com/kolesomusicclub'}
                                    target={'_blank'}
                                    icon={'/assets/images/icons/fb.svg'}
                                />
                                <p>facebook.com/<b>kolesomusicclub</b></p>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} className={classes.social}>
                                <OctagonButton
                                    href={'https://www.instagram.com/kolesoclub/'}
                                    target={'_blank'}
                                    icon={'/assets/images/icons/instagram.svg'}
                                />
                                <p>instagram.com/<b>kolesomusicclub</b></p>
                            </Grid>
                        </Grid>
                        <Box style={{marginBottom: 40}}>
                            <Map/>
                        </Box>
                        <Button onClick={handleGotoEvents} variant={'outlined'} color={'primary'} size={'large'}>Смотреть
                            афишу</Button>
                    </Grid>
                </Grid>
            </ContainerTitled>
        </Layout>
    )
}

export default ContactsPage
