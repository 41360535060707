import {Button, Grid} from '@material-ui/core'
import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {getNews, getYears, loadNews, setCurrentYear} from '../../../redux/actions/news'
import {IState} from '../../../utils/models/IState'
import NewsItem from './NewsItem'

export const NewsList = () => {
    const dispatch = useDispatch()
    const {
        items,
        page,
        totalPages,
        years,
        currentYear
    } = useSelector((state: IState) => state.news, shallowEqual)

    useEffect(() => {
        dispatch(getNews(currentYear))
        !years.length &&
        dispatch(getYears())
    }, [currentYear])

    useEffect(()=>{
        return () => {
            dispatch(setCurrentYear(null))
        }
    }, [])

    const handleLoadMoreClick = () => {
        dispatch(loadNews(page + 1, currentYear))
    }

    return (
        <>
            {items.map((item) => {
                return (
                    <NewsItem item={item} key={item.id}/>
                )
            })}
            {page < (totalPages - 1) &&
            <Grid container style={{margin: '40px 0 50px 0'}}>
                <Grid item xs={12} sm={6} md={4}>
                    <Button
                        onClick={handleLoadMoreClick}
                        variant={'outlined'}
                        color={'primary'}
                        size={'large'}
                        fullWidth
                    >
                        Загрузить ещё
                    </Button>
                </Grid>
            </Grid>
            }
        </>
    )
}

export default NewsList