import React from 'react'
import {ReactElement} from 'react'
import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: 0,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0
            }
        },
        content: {
            padding: '20px 30px 20px 80px',
            [theme.breakpoints.down('sm')]: {
                padding: '20px 0'
            }
        },
        title: {
            '&.bordered': {
                background: 'url(/assets/images/misc/border-h.svg) repeat-x left bottom',
                backgroundSize: 16,
                marginBottom: 0,
                paddingBottom: 20
            },
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                margin: 0,
                fontSize: 20
            }
        }
    }),
)

interface ContainerTitledProps {
    title?: string | number
    children?: ReactElement | any
    bordered?: boolean
}

export const ContainerTitled = (props: ContainerTitledProps) => {
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            {
                props.title ?
                    <Typography variant={'h1'}
                                className={`${classes.title} ${props.bordered && 'bordered'}`}>{props.title}</Typography>
                    :
                    null
            }
            <Box className={classes.content}>
                {props.children}
            </Box>
        </Box>
    )
}
export default ContainerTitled