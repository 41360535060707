import React from 'react';
import {ReactElement} from 'react';
import CenterOnlyLayout from './CenterOnlyLayout'
import EmptyLayout from "./EmptyLayout";
import DefaultLayout from "./DefaultLayout";

interface LayoutProps {
    title?: string
    variant?: 'default' | 'center' | 'empty'
    children?: ReactElement | any
    additionalComponents?: ReactElement[]
}

export const Layout = (props: LayoutProps) => {
    switch (props.variant){
        case 'empty':
            return (
                <EmptyLayout>
                    {props.children}
                </EmptyLayout>
            )
        case 'center':
            return (
                <CenterOnlyLayout title={props.title}>
                    {props.children}
                </CenterOnlyLayout>
            )
        default:
            return (
                <DefaultLayout title={props.title} additionalComponents={props.additionalComponents}>
                    {props.children}
                </DefaultLayout>
            )
    }
}

export default Layout