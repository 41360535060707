import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import ym from 'react-yandex-metrika'
import ContactBlock from '../components/basics/ContactBlock'
import Layout from '../components/layout/Layout'
import {Helmet} from 'react-helmet-async'
import {useParams, useLocation} from 'react-router-dom'
import LeadBill from '../components/misc/Bills/LeadBill'
import ContainerTitled from '../components/misc/ContainerTitled'
import SimpleGalleryList from '../components/misc/Gallery/SimpleGalleryList'
import WithMarkup from '../components/misc/WithMarkup'
import {getCurrentEvent} from '../redux/actions/events'
import {IState} from '../utils/models/IState'

type TParams = {
    id?: string
}

export const EventPage = () => {
    const {pathname} = useLocation()
    const {id} = useParams<TParams>()
    const dispatch = useDispatch()
    const {currentEvent} = useSelector((state: IState) => state.events, shallowEqual)
    const eventTitle = currentEvent ? currentEvent.title : ''

    useEffect(()=>{
        ym('hit', pathname)
        dispatch(getCurrentEvent(id))
    }, [])

    return (
        <Layout title={'Страница мероприятия'} additionalComponents={[
            <ContactBlock key={'contact_block'}/>,
            <SimpleGalleryList key={'simple_gallery'} title={'Последние концерты'} maxWidth={280} last/>
            ]}>
            <Helmet>
                <title>Колесо: Страница мероприятия '{eventTitle}'</title>
            </Helmet>
            {currentEvent &&
            <ContainerTitled>
                <LeadBill
                    imageSrc={currentEvent!.imagePath}
                    date={currentEvent!.startTime}
                    time={currentEvent!.startTime}
                    captionVariant={'sm'}
                    {...currentEvent}
                    style={{marginBottom: 20, paddingBottom: 0}}
                />
                <WithMarkup text={currentEvent!.shortDescription} style={{marginBottom: 20}}/>
                <WithMarkup text={currentEvent!.description} />
            </ContainerTitled>
            }
        </Layout>
    )
}

export default EventPage