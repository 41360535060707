import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {loadUpcomingEvents} from '../../../redux/actions/events'
import {IEvent} from '../../../utils/models/IEvent'
import {IState} from '../../../utils/models/IState'
import LeadBill from './LeadBill'
import {Button, createStyles, Grid, makeStyles} from '@material-ui/core'
import Bill from './Bill'

const useStyles = makeStyles(() =>
    createStyles({
            bill: {
                background: 'url(/assets/images/misc/border-h.svg) repeat-x left bottom',
                backgroundSize: '16px',
            }
        }
    )
)

interface BillListProps {
    component?: 'LeadBill' | 'Bill'
}

export const BillList = (props: BillListProps) => {
    const classes = useStyles()
    const {featured, upcoming, page, totalPages} = useSelector((state: IState) => state.events, shallowEqual)
    const dispatch = useDispatch()

    const handleLoadMoreClick = () => {
        dispatch(loadUpcomingEvents(page + 1))
    }

    switch (props.component) {
        case 'LeadBill':
            return (
                <>
                    {
                        featured &&
                        <LeadBill
                            imageSrc={featured.imagePath}
                            date={featured.startTime}
                            time={featured.startTime}
                            captionVariant={'sm'}
                            className={classes.bill}
                            showMoreBtn={true}
                            link={`/events/${featured.id}`}
                            {...featured}
                        />
                    }
                </>
            )
        default:
            return (
                <>
                    {
                        upcoming.map((item: IEvent, index: number) => (
                            (item.featured && index === 0 && page === 0) ?
                                null :
                            <Bill
                                key={'bill_' + index}
                                imageSrc={item.imagePath}
                                date={item.startTime}
                                time={item.startTime}
                                captionVariant={'sm'}
                                className={classes.bill}
                                {...item}
                            />
                        ))
                    }
                    {page < (totalPages - 1) &&
                    <Grid container style={{margin: '40px 0 50px 0'}}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Button
                                onClick={handleLoadMoreClick}
                                variant={'contained'}
                                color={'primary'}
                                size={'large'}
                                fullWidth
                            >
                                Загрузить ещё
                            </Button>
                        </Grid>
                    </Grid>
                    }
                </>
            )
    }
}
export default BillList