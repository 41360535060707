import {Button, Grid} from '@material-ui/core'
import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {getPosts, loadPosts} from '../../../redux/actions/guestbook'
import {GUESTBOOK_SET_POSTS} from '../../../redux/types'
import {IState} from '../../../utils/models/IState'
import PostItem from './PostItem'

export const GuestbookPostsList = () => {
    const dispatch = useDispatch()
    const {
        items,
        page,
        totalPages,
        posted
    } = useSelector((state: IState) => state.guestbook, shallowEqual)

    useEffect(() => {
        dispatch(getPosts())
    }, [])

    useEffect(() => {
        posted &&
        dispatch(getPosts())
    }, [posted])

    useEffect(()=>{
        return () => {
            dispatch({type: GUESTBOOK_SET_POSTS, payload: []})
        }
    }, [])


    const handleLoadMoreClick = () => {
        dispatch(loadPosts(page + 1))
    }

    return (
        <>
            {items.map((item) => {
                return (
                    <PostItem item={item} key={item.id}/>
                )
            })}
            {page < (totalPages - 1) &&
            <Grid container style={{margin: '40px 0 50px 0'}}>
                <Grid item xs={12} sm={6} md={4}>
                    <Button
                        onClick={handleLoadMoreClick}
                        variant={'outlined'}
                        color={'primary'}
                        size={'large'}
                        fullWidth
                    >
                        Загрузить ещё
                    </Button>
                </Grid>
            </Grid>
            }
        </>
    )
}

export default GuestbookPostsList