import {createStyles, Grid, makeStyles, Theme, withWidth} from '@material-ui/core'
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints'
import React from 'react'
import {IFoodItem} from '../../../utils/models/IFoodState'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            //borderBottom: '1px solid #ccc',
            margin: '0 20px',
            padding: 25,
            boxShadow: '0 8px 5px -4px rgb(0,0,0,0.1)'
        },
        title: {
            fontSize: 16,
            fontWeight: 'bold',
        },
        price: {
            fontWeight: 'bold',
            textAlign: 'right'
        },
        small: {
            fontSize: 12,
            padding: '0 10px'
        }
    }),
)

interface IFoodItemProps extends IFoodItem{
    width?: Breakpoint
}

export const FoodItem: React.FC<IFoodItemProps> = ({title, compound, price, weight, width}) => {
    const classes = useStyles()
    const padding = width === 'xs' || width === 'sm' ? '10px 0' : '0 10px'
    return (
        <Grid container item xs={12} className={classes.root}>
            <Grid className={classes.title} item xs={12} md={4}>{title}</Grid>
            <Grid item xs={12} md={4} className={classes.small} style={{padding}}>{compound}</Grid>
            <Grid item xs={6} md={2} className={classes.small} style={{padding}}>{weight}</Grid>
            <Grid item xs={6} md={2} className={classes.price} style={{padding}}>={price} ₽</Grid>
        </Grid>
    )
}

export default withWidth()(FoodItem)