import axios from 'axios'
import {Dispatch} from 'redux'
import {
    NEWS_ADD_LOADED_NEWS, NEWS_SET_CURRENT_YEAR,
    NEWS_SET_LOADED_PAGE,
    NEWS_SET_NEWS,
    NEWS_SET_TOTAL_PAGES, NEWS_SET_YEARS
} from '../types'

export const getNews = (year: number | null = null) => {
    return async (dispatch: Dispatch) => {
        try {
            const range = year || 'latest'
            const news = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/news/${range}`)
                .then(res => res.data)
            dispatch({type: NEWS_SET_NEWS, payload: news.content})
            dispatch({type: NEWS_SET_LOADED_PAGE, payload: 0})
            dispatch({type: NEWS_SET_TOTAL_PAGES, payload: news.totalPages})
        } catch (err) {
            console.log(err)
        }
    }
}

export const loadNews = (page, year: number | null = null) => {
    return async (dispatch: Dispatch) => {
        try {
            const range = year || 'latest'
            const news = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/news/${range}?page=${page}`)
                .then(res => res.data)
            console.log(news)
            dispatch({type: NEWS_ADD_LOADED_NEWS, payload: news.content})
            dispatch({type: NEWS_SET_LOADED_PAGE, payload: page})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getYears = () => {
    return async (dispatch: Dispatch) => {
        try {
            const years = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/news/years`)
                .then(res => res.data)
            dispatch({type: NEWS_SET_YEARS, payload: years})
        } catch (err) {
            console.log(err)
        }
    }
}

export const setCurrentYear = (year) => {
    return {
        type: NEWS_SET_CURRENT_YEAR,
        payload: year
    }
}

