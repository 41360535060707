import {createStyles, makeStyles, Theme} from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {IState} from '../../../utils/models/IState'
import FoodItem from './FoodItem'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        noItems: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        icon: {
            fontSize: 'min(15vw, 150px)',
            color: 'rgba(0,0,0,0.1)',
            margin: '40px 0 20px 0'
        },
        text:{
            fontSize: 'min(4vw, 30px)',
            color:'rgba(0,0,0,0.15)'
        }
    }),
)

export const FoodItems = () => {
    const classes = useStyles()
    const {items} = useSelector((state: IState) => state.food, shallowEqual)
    return (
        <div className={classes.root}>
            {
                items && items.length > 0 ? items.map((item) => {
                        return <FoodItem key={item.id} {...item} />
                    }) :
                    <div className={classes.noItems}>
                        <Icon className={classes.icon}>restaurant</Icon>
                        <div className={classes.text}>Пока что тут пусто :(</div>
                    </div>
            }
        </div>
    )
}

export default FoodItems