import {IEventsState} from '../../utils/models/IEventsState'
import {
    EVENTS_ADD_LOADED_UPCOMING_EVENTS, EVENTS_SET_CURRENT_EVENT,
    EVENTS_SET_FEATURED_EVENTS,
    EVENTS_SET_LOADED_PAGE,
    EVENTS_SET_TOTAL_PAGES,
    EVENTS_SET_UPCOMING_EVENTS
} from '../types'

export const initialState: IEventsState = {
    featured: null,
    upcoming: [],
    page: 0,
    totalPages: 0,
    currentEvent: null
}

export const eventsReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case EVENTS_SET_FEATURED_EVENTS: {
            return {...state, featured: payload}
        }
        case EVENTS_SET_UPCOMING_EVENTS: {
            return {...state, upcoming: payload}
        }
        case EVENTS_ADD_LOADED_UPCOMING_EVENTS: {
            return {...state, upcoming: state.upcoming.concat(payload)}
        }
        case EVENTS_SET_LOADED_PAGE: {
            return {...state, page: payload}
        }
        case EVENTS_SET_TOTAL_PAGES: {
            return {...state, totalPages: payload}
        }
        case EVENTS_SET_CURRENT_EVENT: {
            return {...state, currentEvent: payload}
        }
        default:
            return state
    }
}