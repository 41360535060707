import {IGalleryState} from '../../utils/models/IGalleryState'
import {
    GALLERY_ADD_LOADED_PHOTOS,
    GALLERY_SET_CURRENT_GALLERY,
    GALLERY_SET_CURRENT_GALLERY_PHOTOS, GALLERY_SET_CURRENT_YEAR,
    GALLERY_SET_LIST,
    GALLERY_SET_LOADED_PAGE,
    GALLERY_SET_TOTAL_PAGES, GALLERY_SET_YEARS,
} from '../types'

export const initialState: IGalleryState = {
    items: [],
    photos: [],
    page: 0,
    totalPages: 0,
    years: [],
    currentYear: 0
}

export const galleryReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case GALLERY_SET_LIST: {
            return {...state, items: payload}
        }
        case GALLERY_SET_CURRENT_GALLERY: {
            return {...state, currentGallery: payload}
        }
        case GALLERY_SET_CURRENT_GALLERY_PHOTOS: {
            return {...state, photos: payload}
        }
        case GALLERY_ADD_LOADED_PHOTOS: {
            return {...state, photos: state.photos.concat(payload)}
        }
        case GALLERY_SET_LOADED_PAGE: {
            return {...state, page: payload}
        }
        case GALLERY_SET_TOTAL_PAGES: {
            return {...state, totalPages: payload}
        }
        case GALLERY_SET_YEARS: {
            return {...state, years: payload}
        }
        case GALLERY_SET_CURRENT_YEAR: {
            return {...state, currentYear: payload}
        }
        default:
            return state
    }
}