import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core'
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints'
import React, {CSSProperties, FC} from 'react'
import OctagonButton, {TOctagonButtonVariants} from './OctagonButton'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root:{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            [theme.breakpoints.only('xs')]: {
                justifyContent: 'space-around'
            }
        }
    }),
)

interface ISocialPanelProps {
  variant?: TOctagonButtonVariants
  className?: string
  style?: CSSProperties
}

export const SocialPanel: FC<ISocialPanelProps> = ({variant, className}) => {
    const classes = useStyles()
    const isInverted = variant === 'invertedSM'
    const commonProps = {
      target: '_blank' as '_blank' | undefined ,
      variant: variant,
      margin: isInverted ? 0 : undefined,
      width: isInverted ? 'xs' as Breakpoint : undefined,
      style: isInverted ? {width: 32, minWidth: undefined} : undefined
    }

    return (
        <Grid container item xs={12} className={`${classes.root} ${className}`} style={isInverted ? {justifyContent: 'flex-end'} : undefined}>
            <OctagonButton
                href={'https://t.me/kolesoclub'}
                icon={'/assets/images/icons/telegram.svg'}
                {...commonProps}
            />
            <OctagonButton
                href={'https://vk.com/clubkoleso'}
                icon={'/assets/images/icons/vk.svg'}
                {...commonProps}
            />
            <OctagonButton
                href={'https://www.instagram.com/kolesoclub/'}
                icon={'/assets/images/icons/instagram.svg'}
                {...commonProps}
            />
        </Grid>
    )
}

export default SocialPanel