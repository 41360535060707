import {Grid, withWidth} from '@material-ui/core'
import React from 'react'
import FoodCategories from './FoodCategories'
import FoodItems from './FoodItems'

export const Food = ({width}) => {
    return (
        <Grid container spacing={0} style={{margin: width === 'xs' || width === 'sm' ? '10px 0' : '50px 0'}}>
            <Grid item xs={2} sm={4} md={3}><FoodCategories /></Grid>
            <Grid item xs={10} sm={8} md={9}><FoodItems /></Grid>
        </Grid>
    )
}

export default withWidth()(Food)