export const APP_SHOW_MENU = 'APP/TOGGLE_MENU'
export const APP_SET_MAIN_MENU = 'APP/SET_MAIN_MENU'
export const APP_SHOW_BANNER = 'APP/SET_SHOW_BANNER'
export const APP_SHOW_DIALOG = 'APP/SHOW_DIALOG'
export const APP_SET_DIALOG_PARAMS = 'APP/SET_DIALOG_PARAMS'

export const EVENTS_SET_FEATURED_EVENTS = 'EVENTS/SET_FEATURED_EVENTS'
export const EVENTS_SET_UPCOMING_EVENTS = 'EVENTS/SET_UPCOMING_EVENTS'
export const EVENTS_ADD_LOADED_UPCOMING_EVENTS = 'EVENTS/ADD_UPCOMING_EVENTS'
export const EVENTS_SET_LOADED_PAGE = 'EVENTS/SET_LOADED_PAGE'
export const EVENTS_SET_TOTAL_PAGES = 'EVENTS/SET_TOTAL_PAGES'
export const EVENTS_SET_CURRENT_EVENT = 'EVENTS/SET_CURRENT_EVENT'

export const NEWS_SET_NEWS = 'NEWS/SET_NEWS'
export const NEWS_ADD_LOADED_NEWS = 'NEWS/ADD_NEWS'
export const NEWS_SET_LOADED_PAGE = 'NEWS/SET_LOADED_PAGE'
export const NEWS_SET_TOTAL_PAGES = 'NEWS/SET_TOTAL_PAGES'
export const NEWS_SET_YEARS = 'NEWS/SET_YEARS'
export const NEWS_SET_CURRENT_YEAR = 'NEWS/SET_CURRENT_YEAR'

export const GUESTBOOK_SET_POSTS = 'GUESTBOOKS/SET_POSTS'
export const GUESTBOOK_ADD_LOADED_POSTS = 'GUESTBOOKS/ADD_POSTS'
export const GUESTBOOK_SET_LOADED_PAGE = 'GUESTBOOKS/SET_LOADED_PAGE'
export const GUESTBOOK_SET_TOTAL_PAGES = 'GUESTBOOKS/SET_TOTAL_PAGES'
export const GUESTBOOK_SET_CAPTCHA = 'GUESTBOOKS/SET_CAPTCHA'
export const GUESTBOOK_SET_POSTED = 'GUESTBOOKS/SET_POSTED'
export const GUESTBOOK_SET_POST_RESULT = 'GUESTBOOKS/SET_POST_RESULT'

export const USER_SET_SUBSCRIBE_RESULT = 'USER/SET_SUBSCRIBE_RESULT'
export const USER_SET_SUBSCRIBE_STATUS = 'USER/SET_SUBSCRIBE_STATUS'

export const GALLERY_SET_LIST = 'GALLERY/SET_LIST'
export const GALLERY_SET_CURRENT_GALLERY = 'GALLERY/SET_CURRENT_GALLERY'
export const GALLERY_SET_CURRENT_GALLERY_PHOTOS = 'GALLERY/SET_CURRENT_GALLERY_PHOTOS'
export const GALLERY_ADD_LOADED_PHOTOS = 'GALLERY/ADD_PHOTOS'
export const GALLERY_SET_LOADED_PAGE = 'GALLERY/SET_LOADED_PAGE'
export const GALLERY_SET_TOTAL_PAGES = 'GALLERY/SET_TOTAL_PAGES'
export const GALLERY_SET_YEARS = 'GALLERY/SET_YEARS'
export const GALLERY_SET_CURRENT_YEAR = 'GALLERY/SET_CURRENT_YEAR'

export const FOOD_SET_CATEGORIES = 'FOOD/SET_CATEGORIES'
export const FOOD_SET_CATEGORY_ITEMS = 'FOOD/SET_CATEGORY_ITEMS'
export const FOOD_SET_CURRENT_CATEGORY= 'FOOD/SET_CURRENT_CATEGORY'