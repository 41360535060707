import Banner from 'components/misc/Banner'
import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import ym from 'react-yandex-metrika'
import {useLocation} from 'react-router-dom'
import {setBanner} from 'redux/actions/app'
import {IAppState} from 'utils/models/IAppState'
import {IState} from 'utils/models/IState'
import ContactBlock from '../components/basics/ContactBlock'
import Layout from '../components/layout/Layout';
import ContainerTitled from "../components/misc/ContainerTitled";
import BillList from "../components/misc/Bills/BillList";
import {Helmet} from "react-helmet-async";
import SimpleGalleryList from '../components/misc/Gallery/SimpleGalleryList'
import {getFeaturedEvents, getUpcomingEvents} from 'redux/actions/events'

export const MainPage = () => {
    const dispatch= useDispatch()
    const {pathname} = useLocation()

    useEffect(()=>{
        ym('hit', pathname)
        dispatch(getFeaturedEvents())
        dispatch(getUpcomingEvents())
    }, [])

    return (
        <Layout title={'Афиша'} additionalComponents={[
            <ContactBlock key={'contact_block'}/>,
            <SimpleGalleryList key={'simple_gallery'} title={'Последние концерты'} maxWidth={280} last />
            ]}>
            <Helmet>
                <title>Колесо: Афиша</title>
            </Helmet>
            <ContainerTitled>
                <BillList component={'LeadBill'}/>
            </ContainerTitled>
            <ContainerTitled title={'Скоро'}>
                <BillList/>
            </ContainerTitled>
        </Layout>
    )
}

export default MainPage