import {IGuestbookState} from '../../utils/models/IGuestbookState'
import {
    GUESTBOOK_ADD_LOADED_POSTS,
    GUESTBOOK_SET_CAPTCHA,
    GUESTBOOK_SET_LOADED_PAGE,
    GUESTBOOK_SET_POST_RESULT,
    GUESTBOOK_SET_POSTED,
    GUESTBOOK_SET_POSTS,
    GUESTBOOK_SET_TOTAL_PAGES
} from '../types'

export const initialState: IGuestbookState = {
    items: [],
    page: 0,
    totalPages: 0,
    captchaImg: '',
    posted: false,
    postResult: []
}

export const guestbookReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case GUESTBOOK_SET_POSTS: {
            return {...state, items: payload}
        }
        case GUESTBOOK_ADD_LOADED_POSTS: {
            return {...state, items: state.items.concat(payload)}
        }
        case GUESTBOOK_SET_LOADED_PAGE: {
            return {...state, page: payload}
        }
        case GUESTBOOK_SET_TOTAL_PAGES: {
            return {...state, totalPages: payload}
        }
        case GUESTBOOK_SET_CAPTCHA: {
            return {...state, captchaImg: payload}
        }
        case GUESTBOOK_SET_POSTED: {
            return {...state, posted: payload}
        }
        case GUESTBOOK_SET_POST_RESULT: {
            return {...state, postResult: payload}
        }
        default:
            return state
    }
}