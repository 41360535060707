import {IUserState} from '../../utils/models/IUserState'
import {USER_SET_SUBSCRIBE_RESULT, USER_SET_SUBSCRIBE_STATUS} from '../types'

export const initialState: IUserState = {
    subscribeResult: [],
    subscribed: false
}

export const userReducer = (state = initialState , {type, payload}) => {
    switch (type) {
        case USER_SET_SUBSCRIBE_RESULT: {
            return {...state, subscribeResult: payload}
        }
        case USER_SET_SUBSCRIBE_STATUS: {
            return {...state, subscribed: payload}
        }
        default:
            return state
    }
}