import {withWidth} from '@material-ui/core'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import ym from 'react-yandex-metrika'
import Layout from '../components/layout/Layout'
import {Helmet} from 'react-helmet-async'
import Food from '../components/misc/Food/Food'

export const FoodPage = ({width}) => {
    const {pathname} = useLocation()

    useEffect(() => {
        ym('hit', pathname)
    }, [])

    return (
        <Layout title={'Меню'} variant={'center'}>
            <Helmet>
                <title>Колесо: Меню</title>
            </Helmet>
            <Food />
        </Layout>
    )
}

export default withWidth()(FoodPage)