import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles, Theme, withWidth
} from '@material-ui/core'
import React from 'react'
import Iframe from 'react-iframe'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {showDialog} from '../../redux/actions/app'
import {IState} from '../../utils/models/IState'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '& .title':{
                fontWeight: 600
            },
            '& .ticketCloud':{
                border: 'none'
            }
        }
    }),
)

export const SimpleDialog = ({width}) => {
    const classes = useStyles()
    const {dialogIsOpen, dialogParams} = useSelector((state: IState) => state.app, shallowEqual)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(showDialog(false))
    }

    return(
        <Dialog fullScreen={width === 'xs'} open={dialogIsOpen} className={classes.root}>
            <DialogTitle className={'title'}>Покупка билета</DialogTitle>
            <DialogContent
              style={{
                width: width === 'xs' ? '100%' : dialogParams.maxWidth,
                boxSizing: 'border-box',
                height: width === 'xs' ? '100%' : undefined
              }}
            >
                <Iframe url={dialogParams.url}
                        width='100%'
                        height={width === 'xs' ? '100%' : '420px'}
                        id='myId'
                        className='ticketCloud'
                        position='relative'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default  withWidth()(SimpleDialog)