import {createStyles, makeStyles} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import React from 'react'
import {INew} from '../../../utils/models/INew'
import WithMarkup from '../WithMarkup'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: 'url(/assets/images/misc/border-h.svg) repeat-x left bottom',
            backgroundSize: '16px',
            paddingBottom: 20,
            position: 'relative'
        },
        icon: {
            width: 23,
            height: 23,
            background: 'url(/assets/images/icons/koleso_gr.svg) no-repeat center center',
            position: 'absolute',
            top: 5,
            left: -45
        }
    }),
)

interface INewProps {
    item: INew
}

export const NewsItem = ({item}: INewProps) => {
    const classes = useStyles()
    return (
        <Box className={classes.root} key={item.id}>
            <Box className={classes.icon}/>
            <h1>{item.title}</h1>
            <p>{item.time && new Date(item.time).toLocaleDateString()}</p>
            <WithMarkup text={item.body}/>
        </Box>
    )
}

export default NewsItem