import React from 'react'
import {useDispatch} from 'react-redux'
import {setDialogParams, showDialog} from '../../../redux/actions/app'
import {dayMonthOptions, timeOptions, weekDayOptions} from '../../../utils/helpers/dateTimeOptions'
import {checkURI} from '../../../utils/helpers/uri'
import Poster, {PosterProps} from '../Poster'
import {Button, createStyles, Grid, makeStyles, Theme} from '@material-ui/core'
import {Link, useHistory} from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: 40,
            paddingBottom: 30,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 20,
                paddingBottom: 15,
            }
        },
        top: {
            marginBottom: 20
        },
        bottom: {
            padding: '20px 0 0 0',
            '& button': {
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginBottom: 10,
                    fontSize: 14,
                    padding: '7px 0'
                },
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    marginBottom: 10,
                    fontSize: 15,
                    padding: '7px 0'
                }
            }
        },
        date: {
            fontSize: 'calc(14px + (33 - 14) * ((100vw - 300px) / (1600 - 300)))',
            [theme.breakpoints.down('sm')]: {
                fontSize: 21
            }
        },
        time: {
            fontSize: 'calc(14px + (33 - 14) * ((100vw - 300px) / (1600 - 300)))',
            textAlign: 'right',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: 21
            }
        },
        price: {
            fontSize: 18,
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
                marginBottom: 10
            }
        }
    }),
)

export interface BillProps extends PosterProps {
    id?: string
    date?: string
    time?: string
    price?: number
    strPrice?: string
    buttonName?: string
    canBuy?: boolean
    showMoreBtn?: boolean
    link?: string
    ticketsUrl?: string
    style?: React.CSSProperties
}

export const LeadBill = (props: BillProps) => {
    const classes = useStyles()
    const history = useHistory()
    // const dispatch = useDispatch()

    const handleMoreClick = () => {
        history.push(`/events/${props.id}`)
    }

    const handleBuyClick = (url) => () =>{
        const checkedUrl = checkURI(url)
        window.open(`${checkedUrl}`, '_blank')
        /*------- In modal impl-----------*/
        // dispatch(setDialogParams({url: checkedUrl, maxWidth: 600}))
    }

    return (
        <Grid container spacing={0} className={classes.root + ' ' + props.className} style={props.style}>
            <Grid item xs={12} container spacing={0} className={classes.top}>
                <Grid item xs={9} className={classes.date}>
                    <b>{props.date && new Date(props.date).toLocaleDateString('ru', weekDayOptions)}</b>,&nbsp;
                    {props.date && new Date(props.date).toLocaleDateString('ru', dayMonthOptions)}
                </Grid>
                <Grid item xs={3} className={classes.time}>
                    {props.time && new Date(props.time).toLocaleTimeString('ru', timeOptions)}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {props.link ?
                    <Link to={props.link}>
                        <Poster
                            imageSrc={props.imageSrc}
                            maxHeight={props.maxHeight}
                            title={props.title}
                            subtitle={props.subtitle}
                            shortDescription={props.shortDescription}
                            captionVariant={props.captionVariant}
                            fader={true}
                        />
                    </Link> :
                    <Poster
                        imageSrc={props.imageSrc}
                        maxHeight={props.maxHeight}
                        title={props.title}
                        subtitle={props.subtitle}
                        shortDescription={props.shortDescription}
                        captionVariant={props.captionVariant}
                        fader={true}
                    />
                }
            </Grid>
            <Grid item xs={12} container className={classes.bottom} spacing={1}>
                <Grid item xs={props.showMoreBtn ? 12 : 6} md={props.strPrice ? 4 : 4} className={classes.price}>
                    {!props.strPrice && props.price ?
                        <>Билеты от&nbsp;<b style={{whiteSpace: 'nowrap'}}>{props.price} ₽</b></>
                        :
                        <b>{props.strPrice}</b>
                    }
                </Grid>
                {props.ticketsUrl &&
                <Grid item xs={6} sm={6} md={4}>
                    <Button
                        onClick={handleBuyClick(props.ticketsUrl)}
                        disableElevation
                        variant={'contained'}
                        color={'primary'}
                        fullWidth
                    >
                        {props.buttonName || 'Купить билет'}
                    </Button>
                </Grid>
                }
                {props.showMoreBtn &&
                <Grid item xs={6} sm={6} md={4}>
                    <Button
                        onClick={handleMoreClick}
                        disableElevation
                        variant={'contained'}
                        color={'secondary'}
                        style={{padding: '5px 0'}}
                        fullWidth
                    >
                        Подробнее
                    </Button>
                </Grid>
                }
            </Grid>
        </Grid>
    )
}
export default LeadBill