import React from 'react'
import {useDispatch} from 'react-redux'
import {setDialogParams} from '../../../redux/actions/app'
import {dayMonthOptions, timeOptions, weekDayOptions} from '../../../utils/helpers/dateTimeOptions'
import {checkURI} from '../../../utils/helpers/uri'
import Poster from '../Poster'
import {Box, Button, createStyles, Grid, makeStyles, Theme} from '@material-ui/core'
import {BillProps} from './LeadBill'
import {Link, useHistory} from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: 40,
            paddingBottom: 30,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 20,
                paddingBottom: 15,
            }
        },
        top: {
            fontSize: 'calc(18px + (24 - 18) * ((100vw - 300px) / (1600 - 300)))',
            marginBottom: 0,
            [theme.breakpoints.down('sm')]: {
                fontSize: 21
            }
        },
        bottom: {
            padding: '15px 0 0 0',
            maxHeight: 120,
            '& button': {
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginBottom: 10,
                    fontSize: 14,
                    padding: '7px 0'
                },
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    marginBottom: 10,
                    fontSize: 15,
                    padding: '7px 0'
                }
            }
        },
        left: {
            display: 'flex',
            flexDirection: 'column'
        },
        description: {
            display: 'flex',
            flexDirection: 'column',
            margin: '0 0 auto 0',
            fontSize: 18,
            '& a': {
                textDecoration: 'none',
                color: '#000'
            },
            '& h2.title': {
                fontSize: 32,
                margin: '10px 0',
                textTransform: 'uppercase',
                lineHeight: '2rem',
                '&:first-child': {
                    marginTop: 0
                },
                [theme.breakpoints.down('xs')]: {
                   fontSize: 24
                }
            },
            [theme.breakpoints.down('xs')]: {
                minHeight: 'auto'
            }
        },
        price: {
            fontSize: 18,
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: 10,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 20
            }
        },
        safeMarkup: {
            '& h1,h2,h3,h4,h5,h6': {
                fontSize: 32,
                margin: 0,
                padding: 0,
                display: 'inline-block'
            },
            '& p': {
                margin: 0
            },
            '&  a': {
                textDecoration: 'underline',
                '&:hover': {
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                }
            }
        }
    }),
)


export const Bill = (props: BillProps) => {
    const classes = useStyles()
    const history = useHistory()
    // const dispatch = useDispatch()

    const handleMoreClick = () => {
        history.push(`/events/${props.id}`)
    }

    const handleBuyClick = (url) => () =>{
        let checkedUrl = checkURI(url)
        window.open(`${checkedUrl}`, '_blank')
        /*------- In modal impl-----------*/
        // dispatch(setDialogParams({url: checkedUrl, maxWidth: 600}))
    }

    return (
        <Grid container spacing={2} className={classes.root + ' ' + props.className}>
            <Grid item xs={12} className={classes.top}>
                <b>
                    {props.date && new Date(props.date).toLocaleDateString('ru', dayMonthOptions)}, <b style={{whiteSpace: 'nowrap'}}>{props.date && new Date(props.date).toLocaleDateString('ru', weekDayOptions)}, {props.time && new Date(props.time).toLocaleTimeString('ru', timeOptions)}</b>
                </b>
            </Grid>
            <Grid item xs={12} md={5}>
                <Link to={`/events/${props.id}`}>
                    <Poster
                        imageSrc={props.imageSrc}
                        maxHeight={props.maxHeight}
                    />
                </Link>
            </Grid>
            <Grid item xs={12} md={7} className={classes.left}>
                <Grid item xs={12} className={classes.description}>
                    <Box>
                        <Link to={`/events/${props.id}`}>
                            <h2 className='title'>{props.title}</h2>
                        </Link>
                        <Box className={classes.safeMarkup}
                             dangerouslySetInnerHTML={{__html: props.shortDescription || ''}}/>
                    </Box>
                </Grid>
                <Grid item xs={12} container className={classes.bottom} spacing={1}>
                    <Grid item xs={12} sm={12} className={classes.price}>
                        {!props.strPrice && props.price ?
                            <>Билеты от&nbsp;<b style={{whiteSpace: 'nowrap'}}>{props.price} ₽</b></>
                            :
                            <b style={{whiteSpace: 'nowrap'}}>{props.strPrice}</b>
                        }
                    </Grid>
                    {props.ticketsUrl &&
                    <Grid item xs={6} sm={7} md={7}>
                        <Button
                            onClick={handleBuyClick(props.ticketsUrl)}
                            disableElevation
                            variant={'contained'}
                            color={'primary'}
                            fullWidth
                        >
                            {props.buttonName || 'Купить билет'}
                        </Button>
                    </Grid>
                    }
                    <Grid item xs={6} sm={5} md={5}>
                        <Button
                            onClick={handleMoreClick}
                            disableElevation
                            variant={'contained'}
                            color={'secondary'}
                            style={{padding: '5px 0'}}
                            fullWidth
                        >
                            Подробнее
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Bill