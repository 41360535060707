import {combineReducers} from 'redux'
import {appReducer} from './appReducer'
import {eventsReducer} from './eventReducer'
import {foodReducer} from './foodReducer'
import {galleryReducer} from './galleryReducer'
import {guestbookReducer} from './guestbookReducer'
import {newsReducer} from './newsReducer'
import {userReducer} from './userReducer'

export const rootReducer = combineReducers({
    app: appReducer,
    events: eventsReducer,
    news: newsReducer,
    user: userReducer,
    guestbook: guestbookReducer,
    gallery: galleryReducer,
    food: foodReducer
})