import axios from 'axios'
import {Dispatch} from 'redux'
import {IBannerConfig} from 'utils/models/IBannerConfig'
import {IDialogParams} from '../../utils/models/IDialogParams'
import {
    APP_SET_DIALOG_PARAMS,
    APP_SET_MAIN_MENU,
    APP_SHOW_DIALOG,
    APP_SHOW_BANNER,
    APP_SHOW_MENU
} from '../types'

export const showMenu = (show = false) => {
    return {
        type: APP_SHOW_MENU,
        payload: show
    }
}

export const showDialog = (show = false) => {
    return {
        type: APP_SHOW_DIALOG,
        payload: show
    }
}

export const setBanner = (config: IBannerConfig) => {
    return {
        type: APP_SHOW_BANNER,
        payload: config
    }
}

export const setDialogParams = (params: IDialogParams) => {
    return (dispatch: Dispatch) => {
        dispatch({type: APP_SET_DIALOG_PARAMS, payload: params})
        dispatch(showDialog(true))
    }
}

export const getMainMenu = () => {
    return async (dispatch: Dispatch) => {
        try {
            const menu = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/common/main_menu`)
                .then(res => res.data)
            dispatch({type: APP_SET_MAIN_MENU, payload: menu})
        } catch (err) {
            console.log(err)
        }
    }
}

