import {createStyles, makeStyles} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import React from 'react'
import {checkURI} from '../../../utils/helpers/uri'
import {IGuestbookPost} from '../../../utils/models/IGuestbookPost'
import WithMarkup from '../WithMarkup'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: 'url(/assets/images/misc/border-h.svg) repeat-x left bottom',
            backgroundSize: '16px',
            paddingBottom: 20,
            position: 'relative',
            lineHeight: '1.6rem',
            '& h1':{
                '& i':{
                    fontSize: 14,
                    fontWeight: 400,
                    whiteSpace: 'nowrap'
                }
            }
        },
        icon: {
            width: 23,
            height: 23,
            background: 'url(/assets/images/icons/koleso_gr.svg) no-repeat center center',
            position: 'absolute',
            top: 0,
            left: -45
        }
    }),
)

interface IPostProps {
    item: IGuestbookPost
}

export const PostItem = ({item}: IPostProps) => {
    const classes = useStyles()
    const checkedUri = checkURI(item.url)
    return (
        <Box className={classes.root} key={item.name}>
            <Box className={classes.icon}/>
            <h1>{item.name} {item.city && (<i>({item.city})</i>)}</h1>
            {item.url &&
            <p><i><a href={`${checkURI(item.url)}`} target='_blank'>{item.url}</a></i></p>
            }
            <p>{item.time && new Date(item.time).toLocaleDateString()}</p>
            <WithMarkup text={item.message}/>
        </Box>
    )
}

export default PostItem