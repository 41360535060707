import axios from 'axios'
import {Dispatch} from 'redux'
import {FOOD_SET_CATEGORIES, FOOD_SET_CATEGORY_ITEMS, FOOD_SET_CURRENT_CATEGORY} from '../types'

export const getCategories = () => {
    return async (dispatch: Dispatch) => {
        try {
            const categories = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/food/categories`)
                .then(res => res.data)
            dispatch({type: FOOD_SET_CATEGORIES, payload: categories})
            dispatch({type: FOOD_SET_CURRENT_CATEGORY, payload: categories[0].id})
            dispatch(getItems(categories[0].id) as any)
        } catch (err) {
            console.log(err)
        }
    }
}

export const setCurrentCategory = (id: string = '') => {
    return async (dispatch: Dispatch) => {
        dispatch({type: FOOD_SET_CURRENT_CATEGORY, payload: id})
    }
}

export const getItems = (id: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(setCurrentCategory(id) as any)
        try {
            const items = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/food/categories/${id}/items`)
                .then(res => res.data)
            dispatch({type: FOOD_SET_CATEGORY_ITEMS, payload: items.content})
        } catch (err) {
            console.log(err)
        }
    }
}