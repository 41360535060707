import {Button, createStyles, makeStyles, Theme} from '@material-ui/core'
import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {showMenu} from '../../redux/actions/app'
import {IMainMenuItem} from '../../utils/models/IMainMenu'
import {IState} from '../../utils/models/IState'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            width: '100%',
            height: '100vh',
            background: theme.palette.primary.dark,
            zIndex: 1000,
            padding: '80px 0 30px 0',
            overflow: 'scroll'
        },
        button:{
            color: '#fff',
            fontSize: 18,
            marginBottom: 10,
            width: 'auto'
        }
    }),
)

export const XSNav = () => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const {menuItems} = useSelector((state: IState) => state.app, shallowEqual)

    const handleClick = (item: IMainMenuItem) => () => {
        history.push(`/${item.code}`)
        dispatch(showMenu(false))
    }

    return (
        <div className={classes.root}>
            {
                menuItems.items.map((item: IMainMenuItem, index) => (
                    <Button
                        className={classes.button}
                        variant={'text'}
                        key={'lnk' + index}
                        onClick={handleClick(item)}
                        disableRipple
                    >
                        {item.title}
                    </Button>
                ))
            }
        </div>
    )
}

export default XSNav