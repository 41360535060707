import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import ym from 'react-yandex-metrika'
import ContactBlock from '../components/basics/ContactBlock'
import Layout from '../components/layout/Layout'
import {Helmet} from 'react-helmet-async'
import ContainerTitled from '../components/misc/ContainerTitled'
import SimpleGalleryList from '../components/misc/Gallery/SimpleGalleryList'
import UnderDevelopment from '../components/misc/UnderDevelopment'

export const OrderPage = () => {
    const {pathname} = useLocation()

    useEffect(() => {
        ym('hit', pathname)
    }, [])

    return (
        <Layout title={'Бронирование'} additionalComponents={[
            <ContactBlock key={'contact_block'}/>,
            <SimpleGalleryList key={'simple_gallery'} title={'Последние концерты'} maxWidth={280} last/>
            ]}>
            <Helmet>
                <title>Колесо: Бронирование</title>
            </Helmet>
            <ContainerTitled>
                <UnderDevelopment/>
            </ContainerTitled>
        </Layout>
    )
}

export default OrderPage