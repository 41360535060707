import {Button, createStyles, makeStyles, TextField, Theme, Typography} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import {useFormik} from 'formik'
import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {subscribe} from '../../redux/actions/user'
import {IState} from '../../utils/models/IState'
import OctagonButton from '../misc/OctagonButton'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 280,
            [theme.breakpoints.only('xs')]: {
                maxWidth: 'none',
                textAlign: 'center'
            },
            '& h1': {
                fontFamily: 'Zantroke',
                fontSize: 29,
                fontWeight: 500,
                [theme.breakpoints.only('sm')]: {
                    fontSize: '3vw'
                },
                '& i':{
                    fontSize: 14
                }
            },
            '& h2, h2 a': {
                fontSize: 48,
                fontWeight: 700,
                margin: 0,
                color: '#000',
                textDecoration: 'none',
                [theme.breakpoints.only('md')]: {
                    fontSize: '3.8vw'
                },
                [theme.breakpoints.only('sm')]: {
                    fontSize: '4.5vw'
                },
            }
        },
        form: {
            margin: '20px 0',
            '& .MuiInputBase-root': {
                marginBottom: 20,
                borderRadius: 10,
                border: 'none'
            },
            '& .MuiOutlinedInput-input': {
                background: '#E5E5E5',
                padding: '10px 15px',
                borderRadius: 10
            }
        },
        telegram: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontSize: 18,
            textAlign: 'left',
            [theme.breakpoints.only('xs')]: {
                maxWidth: 240,
                textAlign: 'center'
            },
        },
        message: {
            '&.success': {
                color: '#21B777'
            },
            '&.error': {
                color: '#ff5252'
            }
        }
    }),
)

export const ContactBlock = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {subscribed, subscribeResult} = useSelector((state: IState) => state.user, shallowEqual)

    const formik = useFormik({
        initialValues: {email: ''},
        onSubmit: (values) => {
            dispatch(subscribe(values))
        },
    })

    const handleChange = (e) => {
        formik.setFieldValue(e.target.name, e.target.value)
    }

    return (
        <Box className={classes.root}>
            <h1>Информация о концертах <i>(c 12:00)</i></h1>
            <h2><a href="tel:+78182471479">47-14-79</a></h2>
            {/*<form className={classes.form} onSubmit={formik.handleSubmit}>
                <p>Подписывайтесь на нашу рассылку и будьте в курсе наших новостей:</p>
                <TextField
                    variant={'outlined'}
                    fullWidth
                    type={'text'}
                    name={'email'}
                    value={formik.values.email}
                    placeholder={'E-Mail'}
                    onChange={handleChange}
                />
                {
                    subscribeResult.length > 0 &&
                    <Box mb={3} className={`${classes.message} ${subscribed ? 'success' : 'error'}`}>
                        {subscribeResult.map((message) => {
                            return (<>{message['message']}<br/></>)
                        })}
                        {subscribed &&
                            <p>На указанный адрес выслано письмо со ссылкой для подтверждения</p>
                        }
                    </Box>
                }
                <Button type={'submit'} variant={'outlined'} fullWidth color={'primary'}
                        size={'large'}>Подписаться</Button>
            </form>*/}
            <Box className={classes.telegram}>
                <p>Или пишите нам сразу в Telegram</p>
                <OctagonButton
                    href={'https://t.me/kolesoclub'}
                    target={'_blank'}
                    icon={'/assets/images/icons/telegram.svg'}
                />
            </Box>
        </Box>
    )
}

export default ContactBlock