import {Button, ButtonProps, createStyles, makeStyles, Theme, withWidth, WithWidthProps} from '@material-ui/core'
import React, {FC} from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 49,
      height: 49,
      background: 'url(/assets/images/misc/octagon.svg) no-repeat center center',
      color: '#fff',
    },
    rootInverted: {
      background: 'none'
    },
  }),
)

export type TOctagonButtonVariants = 'default' | 'contained' | 'invertedSM'

interface IOctagonButton extends Omit<ButtonProps, 'variant'> {
  target?: '_blank'
  icon?: string
  iconSize?: number
  disabled?: boolean
  margin?: number
  variant?: TOctagonButtonVariants
}

interface IOctagonButton extends WithWidthProps {
}

export const OctagonButton: FC<IOctagonButton> = ({
    variant,
    href,
    target,
    icon,
    iconSize,
    disabled,
    margin = 10,
    children,
    width,
    style
  }) => {
  const classes = useStyles()
  return (
    <Button disabled={disabled} className={variant === 'invertedSM' ? classes.rootInverted : classes.root}
            style={{marginRight: margin, minWidth: width === 'xs' ? 49 : 64, ...style}} href={href ?? ''}
            target={target ?? ''}>
      {icon &&
          <img style={{width: iconSize, height: iconSize}} src={icon} alt=''/>
      }
      {children}
    </Button>
  )
}

export default withWidth()(OctagonButton)