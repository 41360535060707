import React from 'react'
import {ReactElement} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {IState} from '../../utils/models/IState'
import Header from '../basics/Header'
import {Box, Container, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core'
import Footer from '../basics/Footer'
import XSNav from '../basics/XSNav'
import ContainerTitled from '../misc/ContainerTitled'
import SimpleDialog from '../misc/SimpleDialog'
import SocialPanel from '../misc/SocialPanel'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
        },
        container: {
            maxWidth: 1440,
            margin: ' 0 auto auto auto',
            padding: '45px 20px 0 20px',
            minHeight: '100%',
            [theme.breakpoints.down('sm')]: {
                paddingTop: 15
            }
        },
        mainZone: {
            padding: '0 20px 30px 0',
            [theme.breakpoints.down('xs')]: {
                padding: 0,
                margin: '15px 0'
            }
        },
        rightZone: {
            padding: '0 0 30px 50px',
            background: 'url(/assets/images/misc/border-v.svg) repeat-y left top',
            backgroundSize: '2px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 30,
                paddingRight: 20
            },
            [theme.breakpoints.down('xs')]: {
                background: 'none',
                padding: 0,
                margin: '15px 0'
            }
        },
        footer: {
            position: 'sticky',
            bottom: -135,
            height: 135,
            marginTop: 80,
            background: '#DBDBDB',
            [theme.breakpoints.only('xs')]: {
                position: 'static',
                bottom: 0,
                height: 'auto',
                marginTop: 30,
            }
        },
        title: {
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                margin: 0,
                fontSize: 20
            }
        }
    }),
)

interface ICenterOnlyLayoutProps {
    title?: string
    children: ReactElement | any
}

export const CenterOnlyLayout = (props: ICenterOnlyLayoutProps) => {
    const classes = useStyles()
    const {showMenu: menuIsShown} = useSelector((state: IState) => state.app, shallowEqual)
    return (
        <Box className={classes.root}>
            {menuIsShown && <XSNav/>}
            <Header/>
            <Container disableGutters className={classes.container}>
                <Grid container spacing={0}>
                    {
                        props.title ?
                            <Grid item xs={12}>
                                <Typography variant={'h1'} className={classes.title}>{props.title}</Typography>
                            </Grid> :
                            null
                    }
                    <Grid item xs={12} className={classes.mainZone}>
                        {props.children}
                    </Grid>
                </Grid>
                <ContainerTitled>
                    <SocialPanel style={{marginTop: 50}}/>
                </ContainerTitled>
            </Container>
            <Footer className={classes.footer}/>
            <SimpleDialog />
        </Box>
    )
}
export default CenterOnlyLayout