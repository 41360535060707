import React from 'react'
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            overflow: 'hidden',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            textAlign: 'left',
        },
        caption: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            padding: '0 20px 30px 20px',
            color: '#fff',
            fontSize: 'calc(14px + (48 - 14) * ((100vw - 300px) / (1600 - 300)))',
            fontWeight: 700,
            textTransform: 'uppercase',
            zIndex: 100,
            '& p': {
                margin: '20px 0 0 0'
            },
            '& span': {
                //whiteSpace: 'nowrap'
            },
            [theme.breakpoints.up('sm')]: {
                lineHeight: '1.8rem',
            },
            [theme.breakpoints.only('xs')]: {
                fontSize: 28,
                lineHeight: '24px',
                paddingBottom: 15
            },
            [theme.breakpoints.up('md')]: {
                fontSize: 'calc(14px + (36 - 14) * ((100vw - 300px) / (1600 - 300)))',
                lineHeight: '42px',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 42,
                lineHeight: '42px',
            },
        },
        captionXs: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            padding: '0 20px 20px 20px',
            color: '#fff',
            fontSize: '1.3vw',
            fontWeight: 700,
            textTransform: 'uppercase',
            zIndex: 100,
            '& p': {
                margin: '20px 0 0 0'
            },
            '& span': {
                //whiteSpace: 'nowrap'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '3vw',
                lineHeight: '1.8rem',
            },
            [theme.breakpoints.only('xs')]: {
                fontSize: 28,
                lineHeight: '24px',
                paddingBottom: 15
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 20,
                lineHeight: '24px',
                paddingBottom: 15
            }
        },
        subtitle: {
            fontSize: 'calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)))',
            fontWeight: 400,
            textTransform: 'none',
            whiteSpace: 'nowrap'
        },
        fader: {
            position: 'absolute',
            bottom: 0,
            height: '40%',
            width: '100%',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 77.38%)',
        }
    }),
)

export interface PosterProps {
    imageSrc: string | undefined
    maxHeight?: number | string
    maxWidth?: number | string
    caption?: string | undefined
    captionVariant?: 'lg' | 'sm' | 'xs'
    className?: string
    title?: string
    subtitle?: string
    shortDescription?: string
    fader?: boolean
    appImage?: boolean
}

export const Poster = (props: PosterProps) => {
    const classes = useStyles()
    return (
        <Box className={classes.root} style={{maxHeight: props.maxHeight, maxWidth: props.maxWidth}}>
            {props.imageSrc &&
            <img src={`${!props.appImage ? (process.env.REACT_APP_API_URL + '/files/') : ''}${props.imageSrc}`}
                 width={'100%'} alt={'poster'}/>
            }
            <Box className={props.captionVariant === 'xs' ? classes.captionXs : classes.caption}>
                <p>
                    <span>{props.title}</span> {props.subtitle &&
                <span className={classes.subtitle}>({props.subtitle})</span>
                }
                </p>
            </Box>
            {props.fader && <Box className={classes.fader}/>}
        </Box>
    )
}
export default Poster