import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom'
import ScrollToTop from '../components/misc/ScrollToTop'
import AboutPage from '../pages/AboutPage'
import ContactsPage from '../pages/ContactsPage'
import EventPage from '../pages/EventPage'
import FoodPage from '../pages/FoodPage'
import GalleriesPage from '../pages/GalleriesPage'
import GalleryPage from '../pages/GalleryPage'
import GuestbookPage from '../pages/GuestbookPage'
import MainPage from '../pages/MainPage'
import NewsPage from '../pages/NewsPage'
import OrderPage from '../pages/OrderPage'
import RiderPage from '../pages/RiderPage'
import TimePage from '../pages/TimePage'

const routes = [
    {
        path: '/news',
        component: NewsPage
    },
    {
        path: '/guest_book',
        component: GuestbookPage
    },
    {
        path: '/about',
        component: AboutPage
    },
    {
        path: '/order',
        component: OrderPage
    },
    {
        path: '/gallery/:year/:id',
        component: GalleryPage
    },
    {
        path: '/gallery',
        component: GalleriesPage
    },
    {
        path: '/serv',
        component: FoodPage
    },
    {
        path: '/contacts',
        component: ContactsPage
    },
    {
        path: '/rider',
        component: RiderPage
    },
    {
        path: '/time',
        component: TimePage
    },
    {
        path: '/events/:id',
        component: EventPage
    },
    {
        path: '/',
        component: MainPage
    },
]

export default function RouteConfig() {
    return (
        <Router>
            <ScrollToTop/>
            <Switch>
                {routes.map((route) => (
                    <Route path={route.path} key={route.path}>
                        <route.component/>
                    </Route>
                ))}
            </Switch>
        </Router>
    )
}