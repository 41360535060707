import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core'
import React from 'react'
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
           display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#ddd',
            margin: '40px 0',
            fontSize: '2.5vw',
            letterSpacing: '-0.15vw',
            '& .material-icons':{
               fontSize: '10vw'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '3.5vw',
                '& .material-icons':{
                    fontSize: '14vw'
                },
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '5.5vw',
                '& .material-icons':{
                    fontSize: '18vw'
                },
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '7.5vw',
                '& .material-icons':{
                    fontSize: '21vw'
                },
            }
        },
    }),
)

export const UnderDevelopment = () => {
    const classes = useStyles()
    return(
        <div className={classes.root}>
            <Icon className={'material-icons-outlined'}>pending</Icon>
            <div>В разработке</div>
        </div>
    )
}

export default UnderDevelopment