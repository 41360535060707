import React, {CSSProperties} from 'react'

interface ILogoProps {
    variant?: 'dark' | 'light'
    size?: number
    style?: CSSProperties
    onClick?: () => void
}

export const Logo: React.FC<ILogoProps> = ({variant, size, style, onClick}) => {
    let src: string
    switch (variant) {
        case 'dark':
            src = '/assets/images/logo-d.svg'
            break
        default:
            src = '/assets/images/logo-l.svg'
    }
    return (
        <img onClick={onClick} src={src} alt={'logo'} style={{width: '100%', maxWidth: size, cursor: onClick ? 'pointer' : 'default', ...style}}/>
    )
}
export default Logo