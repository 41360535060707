import {createStyles, makeStyles} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import React from 'react'
import theme from '../../theme'

const useStyles = makeStyles(() =>
    createStyles({
        safeMarkup: {
            fontWeight: 500,
            '& h1,h2,h3,h4,h5,h6': {
                fontSize: 32,
                margin: '0 0 20px 0',
                padding: 0,
                display: 'inline-block',
                fontFamily: 'Zantroke',
                fontWeight: 500
            },
            '& h2': {
                fontSize: 21,
            },
            '& h3,h4,h5,h6': {
                fontSize: 16,
            },
            '& p': {
                margin: '0 0 20px 0',
                fontFamily: 'Montserrat!important',
                lineHeight: '1.6rem'
            },
            '& ul, ol': {
                margin: '20px 0',
                padding: '0 20px',
                '& li': {
                    margin: '10px 0',
                    padding: 0
                }
            },
            '& a': {
                color: theme.palette.primary.main,
                textDecoration: 'none',
                '&:hover': {
                    textDecoration: 'underline',
                }
            },
            '& del': {
                color: theme.palette.secondary.main
            }
        }
    }),
)

interface IWithMarkup {
    text?: string
    style?: React.CSSProperties
}

export const WithMarkup = ({text, style}: IWithMarkup) => {
    const classes = useStyles()
    return(
        <Box
            className={classes.safeMarkup}
            dangerouslySetInnerHTML={{__html: text || ''}}
            style={style}
        />
    )
}

export default WithMarkup