import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import ym from 'react-yandex-metrika'
import ContactBlock from '../components/basics/ContactBlock'
import Layout from '../components/layout/Layout'
import {Helmet} from 'react-helmet-async'
import ContainerTitled from '../components/misc/ContainerTitled'
import SimpleGalleryList from '../components/misc/Gallery/SimpleGalleryList'
import YearsPanel from '../components/misc/YearsPanel'
import {getGalleryYears} from '../redux/actions/gallery'
import {IState} from '../utils/models/IState'

export const GalleriesPage = () => {
    const {pathname} = useLocation()
    const {years, currentYear} = useSelector((state: IState) => state.gallery, shallowEqual)
    const dispatch = useDispatch()

    useEffect(()=>{
        ym('hit', pathname)
        dispatch(getGalleryYears())
    }, [])

    return (
        <Layout
            title={'Галерея'}
            variant={'center'}
            additionalComponents={[
                <ContactBlock key={'contact_block'}/>
            ]}
        >
            <Helmet>
                <title>Колесо: Галерея</title>
            </Helmet>
            <ContainerTitled>
                <YearsPanel data={years}/>
            </ContainerTitled>
            <ContainerTitled title={currentYear} bordered>
                <SimpleGalleryList
                    key={'simple_gallery'}
                    variant={'row'}
                    gutter={30}
                    dateOnTop
                />
            </ContainerTitled>
        </Layout>
    )
}

export default GalleriesPage