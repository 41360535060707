import React from 'react'
import {
    createStyles,
    makeStyles,
    Theme,
    Typography,
    withWidth
} from '@material-ui/core'
import {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {getGalleryItems, getGalleryLastItems, getGalleryYears} from '../../../redux/actions/gallery'
import {GALLERY_SET_CURRENT_YEAR} from '../../../redux/types'
import {dayMonthOptions} from '../../../utils/helpers/dateTimeOptions'
import {IGallery} from '../../../utils/models/IGallery'
import {IState} from '../../../utils/models/IState'
import Poster from '../Poster'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: 30,
            [theme.breakpoints.only('xs')]: {
                maxWidth: 'none',
                textAlign: 'center'
            },
            '& h4, h5': {
                fontFamily: 'Zantroke',
                fontSize: 20,
                fontWeight: 500,
                marginBottom: 20,
                [theme.breakpoints.only('sm')]: {
                    fontSize: 18
                },
            },
            '& h5': {
                marginBottom: 10
            }
        },
    }),
)

export interface ISimpleGalleryList {
    title?: string
    variant?: 'column' | 'row'
    gutter?: number
    dateOnTop?: boolean
    maxWidth?: number | string
    width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
    last?: boolean
}

export const SimpleGalleryList = ({title, variant, gutter, dateOnTop, maxWidth, width, last = false}: ISimpleGalleryList) => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const {items, currentYear, years} = useSelector((state: IState) => state.gallery, shallowEqual)

    const setLastYear = (years) => {
        const year = years[years.length - 1]
        dispatch({type: GALLERY_SET_CURRENT_YEAR, payload: year})
    }

    useEffect(() => {
        if(!last) {
            currentYear && dispatch(getGalleryItems(currentYear))
        }else {
            dispatch(getGalleryLastItems())
        }
    }, [currentYear])

    useEffect(()=>{
        if(!currentYear) {
            setLastYear(years)
        }
    }, [years])

    useEffect(()=>{
        !years || years.length === 0 && dispatch(getGalleryYears())
        return () => {
            setLastYear(years)
        }
    },[])

    const handleClick = (item: IGallery) => () => {
        const year = item.date?.split('-')[0]
        history.push(`/gallery/${year}/${item.id}`)
    }

    return (
        <div className={classes.root} style={{maxWidth: width !== 'xs' ? maxWidth : 'none', flexDirection: variant}}>
            <Typography variant={'h4'}>{title}</Typography>
            {
                items.map((item) => {
                    return (
                        <div
                            key={item.id}
                            style={{marginBottom: 20, cursor: 'pointer', marginRight: gutter}}
                            onClick={handleClick(item)}
                        >
                            {dateOnTop &&
                            <Typography
                                variant={'h5'}>{item.date && new Date(item.date).toLocaleDateString('ru', dayMonthOptions)}</Typography>
                            }
                            <Poster
                                imageSrc={item.imagePath}
                                caption={title}
                                title={item.title}
                                captionVariant={'xs'}
                                maxWidth={295}
                                maxHeight={variant === 'row' ? 205 : ''}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default withWidth()(SimpleGalleryList)