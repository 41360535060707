import {IFoodState} from '../../utils/models/IFoodState'
import {
    FOOD_SET_CATEGORIES, FOOD_SET_CATEGORY_ITEMS, FOOD_SET_CURRENT_CATEGORY

} from '../types'

export const initialState: IFoodState = {
    categories: [],
    items: [],
    currentCategoryId: ''
}

export const foodReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case FOOD_SET_CATEGORIES: {
            return {...state, categories: payload}
        }
        case FOOD_SET_CATEGORY_ITEMS: {
            return {...state, items: payload}
        }
        case FOOD_SET_CURRENT_CATEGORY: {
            return {...state, currentCategoryId: payload}
        }
        default:
            return state
    }
}