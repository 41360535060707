import axios from 'axios'
import {Dispatch} from 'redux'
import {USER_SET_SUBSCRIBE_RESULT, USER_SET_SUBSCRIBE_STATUS} from '../types'

export const subscribe = (form) => {
    return async (dispatch: Dispatch) => {
        try {
            const subscribe = await axios.post(`${process.env.REACT_APP_API_URL}/api/site/subscriber/subscribe?email=${form.email}`)
                .then(res => res.data)
            dispatch({type: USER_SET_SUBSCRIBE_STATUS, payload: true})
            dispatch({type: USER_SET_SUBSCRIBE_RESULT, payload: subscribe.messages})
        } catch (err: any) {
            dispatch({type: USER_SET_SUBSCRIBE_STATUS, payload: false})
            if (err.response.data.messages) {
                dispatch({type: USER_SET_SUBSCRIBE_RESULT, payload: err.response.data.messages})
            }
        }
    }
}