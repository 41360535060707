import {Button, createStyles, Grid, makeStyles, Typography} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import Carousel, {Modal, ModalGateway} from 'react-images'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import ym from 'react-yandex-metrika'
import Layout from '../components/layout/Layout'
import {Helmet} from 'react-helmet-async'
import {useLocation, useParams} from 'react-router-dom'
import ContainerTitled from '../components/misc/ContainerTitled'
import YearsPanel from '../components/misc/YearsPanel'
import {getCurrentGallery, getGalleryPhotos, getGalleryYears, loadPhotos} from '../redux/actions/gallery'
import {GALLERY_SET_CURRENT_YEAR} from '../redux/types'
import {dayMonthOptions} from '../utils/helpers/dateTimeOptions'
import {IState} from '../utils/models/IState'
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry'

const useStyles = makeStyles(() =>
    createStyles({
        gallery: {
            marginTop: 30,
            '& img': {
                maxWidth: '100%',
                cursor: 'pointer',
                width: '100%',
            }
        },
        years: {
            margin: '0 0 30px 0',
            '& .MuiButton-text': {
                margin: 0
            },
            '& button span.MuiButton-label': {
                margin: 0,
                padding: 0
            }
        }
    }),
)

type TParams = {
    id?: string
    year: string
}

export const GalleryPage = () => {
    const {pathname} = useLocation()
    const classes = useStyles()
    const {id, year} = useParams<TParams>()
    const dispatch = useDispatch()
    const {
        currentGallery,
        photos,
        page,
        totalPages,
        years} = useSelector((state: IState) => state.gallery, shallowEqual)

    const [currentImage, setCurrentImage] = useState(0)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)

    const openLightbox = (index) => () => {
        setCurrentImage(index)
        setViewerIsOpen(true)
    }

    const closeLightbox = () => {
        setCurrentImage(0)
        setViewerIsOpen(false)
    }

    const handleLoadMoreClick = () => {
        dispatch(loadPhotos(currentGallery?.id, page + 1))
    }

    useEffect(() => {
        ym('hit', pathname)
        dispatch({type: GALLERY_SET_CURRENT_YEAR, payload: parseInt(year)})
        dispatch(getGalleryYears())
        dispatch(getCurrentGallery(id))
        dispatch(getGalleryPhotos(id))
    }, [])

    return (
        <Layout variant={'center'} title={`Галерея`}>
            <Helmet>
                <title>{`Колесо: Галерея ${currentGallery?.title}`}</title>
            </Helmet>
            <ContainerTitled>
                <YearsPanel data={years} withRedirect/>
            </ContainerTitled>
            {currentGallery &&
            <ContainerTitled>
                <Typography variant={'h4'}
                            style={{fontWeight: 600}}>{currentGallery.date && new Date(currentGallery.date).toLocaleDateString('ru', dayMonthOptions)}</Typography>
                <Typography variant={'h3'} style={{fontWeight: 700}}>{currentGallery.title}</Typography>
                <div className={classes.gallery}>
                    {photos && photos.length > 0 ?
                        <>
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{350: 2, 400: 2, 750: 3, 900: 4}}
                            >
                                <Masonry columnsCount={4} gutter={'10px'}>
                                    {photos?.map((photo, index) => (
                                        <img
                                            key={photo.id}
                                            src={`${process.env.REACT_APP_API_URL}/files/${photo.imagePath}`}
                                            onClick={openLightbox(index)}
                                        />
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                            <ModalGateway>
                                {viewerIsOpen ? (
                                    <Modal onClose={closeLightbox}>
                                        <Carousel
                                            currentIndex={currentImage}
                                            views={photos.map(x => ({
                                                ...x,
                                                source: `${process.env.REACT_APP_API_URL}/files/${x.imagePath}`,
                                                srcset: `${process.env.REACT_APP_API_URL}/files/${x.imagePath}`,
                                                caption: `${currentGallery.date && new Date(currentGallery.date).toLocaleDateString('ru', dayMonthOptions)} - ${currentGallery.title}`
                                            }))}
                                        />
                                    </Modal>
                                ) : null}
                            </ModalGateway>
                        </> :
                        <Typography variant={'h6'}>Фотографии скоро появятся</Typography>
                    }
                </div>
                {page < (totalPages - 1) &&
                <Grid container style={{margin: '40px 0 50px 0'}}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Button
                            onClick={handleLoadMoreClick}
                            variant={'outlined'}
                            color={'primary'}
                            size={'large'}
                            fullWidth
                        >
                            Загрузить ещё
                        </Button>
                    </Grid>
                </Grid>
                }
            </ContainerTitled>
            }
        </Layout>
    )
}

export default GalleryPage