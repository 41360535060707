import {IAppState} from 'utils/models/IAppState'
import {APP_SET_DIALOG_PARAMS, APP_SET_MAIN_MENU, APP_SHOW_BANNER, APP_SHOW_DIALOG, APP_SHOW_MENU} from '../types'

export const initialState: IAppState = {
    showMenu: false,
    menuItems: {items: []},
    dialogIsOpen: false,
    dialogParams: {url: ''},
    banner: {
        show: true,
        wasShowed: false,
    }
}

export const appReducer = (state = initialState , {type, payload}) => {
    switch (type) {
        case APP_SHOW_MENU: {
            return {...state, showMenu: payload}
        }
        case APP_SHOW_DIALOG: {
            return {...state, dialogIsOpen: payload}
        }
        case APP_SET_DIALOG_PARAMS: {
            return {...state, dialogParams: payload}
        }
        case APP_SET_MAIN_MENU: {
            return {...state, menuItems: {items: payload}}
        }
        case APP_SHOW_BANNER: {
            return {...state, banner: payload}
        }
        default:
            return state
    }
}