import React from 'react'
import {ReactElement} from 'react'
import SimpleDialog from '../misc/SimpleDialog'

interface EmptyLayoutProps {
    children: ReactElement | any
}

export const EmptyLayout = (props: EmptyLayoutProps) => {
    return (
        <>
            {props.children}
            <SimpleDialog />
        </>
    )
}
export default EmptyLayout