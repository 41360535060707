export const timeOptions = {
    hour: '2-digit',
    minute: '2-digit'
} as const

export const fullDateOptions = {
    month: 'long',
    day: '2-digit',
    weekday: 'long'
} as const

export const dayMonthOptions = {
    month: 'long',
    day: '2-digit'
} as const

export const weekDayOptions = {
    weekday: 'long'
} as const