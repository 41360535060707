import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {getCategories} from '../../../redux/actions/food'
import {IState} from '../../../utils/models/IState'
import FoodCategory from './FoodCategory'

export const FoodCategories = () => {
    const {categories} = useSelector((state: IState) => state.food, shallowEqual)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCategories())
    }, [])

    return (
        <>
            {
                categories && categories.map(category => {
                    return <FoodCategory key={category.id} {...category} />
                })
            }
        </>
    )
}

export default FoodCategories