import axios from 'axios'
import {Dispatch} from 'redux'
import {
    EVENTS_ADD_LOADED_UPCOMING_EVENTS, EVENTS_SET_CURRENT_EVENT,
    EVENTS_SET_FEATURED_EVENTS,
    EVENTS_SET_LOADED_PAGE,
    EVENTS_SET_TOTAL_PAGES,
    EVENTS_SET_UPCOMING_EVENTS
} from '../types'

export const getFeaturedEvents = () => {
    return async (dispatch: Dispatch) => {
        try {
            const event = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/events/featured`)
                .then(res => res.data)
            dispatch({type: EVENTS_SET_FEATURED_EVENTS, payload: event})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getUpcomingEvents = () => {
    return async (dispatch: Dispatch) => {
        try {
            const events = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/events/upcoming?page=0`)
                .then(res => res.data)
            dispatch({type: EVENTS_SET_UPCOMING_EVENTS, payload: events.content})
            dispatch({type: EVENTS_SET_LOADED_PAGE, payload: 0})
            dispatch({type: EVENTS_SET_TOTAL_PAGES, payload: events.totalPages})
        } catch (err) {
            console.log(err)
        }
    }
}

export const loadUpcomingEvents = (page) => {
    return async (dispatch: Dispatch) => {
        try {
            const events = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/events/upcoming?page=${page}`)
                .then(res => res.data)
            dispatch({type: EVENTS_ADD_LOADED_UPCOMING_EVENTS, payload: events.content})
            dispatch({type: EVENTS_SET_LOADED_PAGE, payload: page})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getCurrentEvent = (id) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({type: EVENTS_SET_CURRENT_EVENT, payload: null})
            const event = await axios.get(`${process.env.REACT_APP_API_URL}/api/site/events/${id}`)
                .then(res => res.data)
            dispatch({type: EVENTS_SET_CURRENT_EVENT, payload: event})
        } catch (err) {
            console.log(err)
        }
    }
}