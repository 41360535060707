import React from 'react'
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import L from 'leaflet';

const iconPerson = new L.Icon({
    iconUrl: '/assets/images/icons/koleso_bl.svg',
    iconRetinaUrl: '/assets/images/icons/koleso_bl.svg',
    iconSize: new L.Point(28, 28),
    className: 'leaflet-div-icon'
});


const Map:React.FC = () => {
    return (
    <MapContainer
        className="markercluster-map"
        center={[64.5529907, 40.5182561]}
        zoom={18}
        //minZoom={18}
        maxZoom={18}
        id="mapId"
    >
        <Marker
            position={[64.5529907, 40.5182561]}
            icon={ iconPerson }
        >
        </Marker>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
    </MapContainer>
    )
}

export default Map