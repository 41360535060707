import React from 'react';
import {YMInitializer} from 'react-yandex-metrika'
import RouteConfig from './router/routes'

function App() {
    return (
        <div className='App'>
            <YMInitializer
              accounts={[71235667]}
              options={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
                trackHash: false
              }}
              version='2'
            />
            <RouteConfig/>
        </div>
    );
}

export default App;
