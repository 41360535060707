import {INewsState} from '../../utils/models/INewsState'
import {
    NEWS_ADD_LOADED_NEWS, NEWS_SET_CURRENT_YEAR,
    NEWS_SET_LOADED_PAGE,
    NEWS_SET_NEWS,
    NEWS_SET_TOTAL_PAGES, NEWS_SET_YEARS
} from '../types'

export const initialState: INewsState = {
    items: [],
    page: 0,
    totalPages: 0,
    years: [],
    currentYear: null
}

export const newsReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case NEWS_SET_NEWS: {
            return {...state, items: payload}
        }
        case NEWS_ADD_LOADED_NEWS: {
            return {...state, items: state.items.concat(payload)}
        }
        case NEWS_SET_LOADED_PAGE: {
            return {...state, page: payload}
        }
        case NEWS_SET_TOTAL_PAGES: {
            return {...state, totalPages: payload}
        }
        case NEWS_SET_YEARS: {
            return {...state, years: payload}
        }
        case NEWS_SET_CURRENT_YEAR: {
            return {...state, currentYear: payload}
        }
        default:
            return state
    }
}