import {Button, createStyles, Hidden, makeStyles} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {getYears, setCurrentYear} from '../../../redux/actions/news'
import {IState} from '../../../utils/models/IState'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: 'url(/assets/images/misc/border-h.svg) repeat-x left bottom',
            backgroundSize: '16px',
            paddingBottom: 20,
            marginTop: 25,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            '& button': {
                maxWidth: 80
            }
        }
    }),
)

export const NewsYears = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {
        years,
        currentYear
    } = useSelector((state: IState) => state.news, shallowEqual)

    useEffect(() => {
        dispatch(getYears())
    }, [])

    const handleClick = (year) => () => {
        dispatch(setCurrentYear(year))
    }

    return (
        <Hidden xsDown>
            <Box className={classes.root}>
                <h2>Архив новостей</h2>
                <Button
                    variant={'text'}
                    color={currentYear === null ? 'primary' : 'secondary'}
                    onClick={handleClick(null)}>
                    Все
                </Button>
                {
                    years.map((year) => {
                        return <Button
                            variant={'text'}
                            color={year === currentYear ? 'primary' : 'secondary'}
                            key={year}
                            onClick={handleClick(year)}>
                            {year}
                        </Button>
                    })
                }
            </Box>
        </Hidden>
    )
}

export default NewsYears